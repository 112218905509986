import React, { Component } from 'react'
import SinglePostPage from "../components/Pages/SinglePostPage/SinglePostPage"
import PageWrapper from '../components/PageWrapper/PageWrapper'

class Post extends Component {
  render() {
    return (
      <PageWrapper>
        <SinglePostPage {...this.props}/>
      </PageWrapper>
    )
  }
}

export default Post
