import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { BLOG_POSTS } from '../../../constants/constants';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import Icon from '../../UI/Icon/Icon';
import SEO from '../../seo';
import PostPreview from '../../UI/PostPreview/PostPreview';

import './SinglePostPage.sass';

class SinglePostPage extends React.Component<{
  pageContext: any
}> {

  onServiceClick = (link) => () => {
    navigate(link);
  };

  render() {
    const { data } = this.props.pageContext;
    if (!data) return null;
    const { title, lead, date, content, author } = data;

    return (
      <div className='single-post'>
        <SEO title={title}/>
        <Header/>
        <div className='single-post__intro'>
          <div className='max-width'>
            <Link to='blog-posts' className='single-post__back'>
              <Icon name='icon-arrow-left'/>
              <span>
                Back To All Posts
              </span>
            </Link>
          </div>
        </div>
        <div className='single-post__post-info max-width'>
          <h2 className='single-post__post-title'>{title}</h2>
          <h5 className='single-post__post-lead'>
            {lead}
          </h5>
          <div className='single-post__post-meta'>
            <div className='single-post__post-meta-left'>
              <div className='single-post__post-author'>
                {author}
              </div>
              <div className='single-post__post-date'>
                {date}
              </div>
            </div>
            <div className='single-post__post-meta-right'>
              <Icon name='icon-twitter'/>
              <Icon name='icon-facebook'/>
            </div>
          </div>
        </div>
        <div className='single-post__content max-width'
             dangerouslySetInnerHTML={{ __html : content }}/>
        <div className='max-width'>
          <h3>Related Posts</h3>
          <div className='single-post__related blog__content-list'>
            {BLOG_POSTS.slice(0, 3).map(item => <PostPreview {...item} key={item.id}/>)}
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default SinglePostPage;
